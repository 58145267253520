const assetsBlock = document.querySelector('section.assets');

if(assetsBlock) {
    const assets = assetsBlock.querySelectorAll('.tease-asset');
    assets.forEach(function(asset) {
        const video = asset.querySelector('video');
        if (video) {
            const source = video.getAttribute('data-src');
            if (source) {
                const preloadVideo = () => {
                    if (video.querySelector('source').getAttribute('src') === '') {
                        video.querySelector('source').setAttribute('src', source);
                        video.load();
                    }
                };

                // Preload when in viewport
                if ('IntersectionObserver' in window) {
                    const observer = new IntersectionObserver((entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                setTimeout(preloadVideo, 100);
                                observer.unobserve(video);
                            }
                        });
                    });
                    observer.observe(video);
                } else {
                    setTimeout(preloadVideo, 1000);
                }
            }
        }
    });

    assets.forEach(function(asset) {
        const video = asset.querySelector('video');
        const img = asset.querySelector('img');
        if (video) {

            const handleVideoLoaded = () => {
                video.play();
                if (img) {
                    img.classList.add('hide');
                }
                video.classList.add('show');
            };

            asset.addEventListener('mouseover', function() {
                if (video) {
                    const source = video.getAttribute('data-src');
                    const currentSrc = video.querySelector('source').getAttribute('src');
                    if (currentSrc === '') {
                        video.querySelector('source').setAttribute('src', source);
                        video.load();
                        video.addEventListener('loadeddata', handleVideoLoaded, { once: true });
                    } else if (video.readyState >= 3) {
                        handleVideoLoaded();
                    } else {
                        video.addEventListener('loadeddata', handleVideoLoaded, { once: true });
                    }
                }
            });

            asset.addEventListener('mouseleave', function() {
                if (video) {
                    video.removeEventListener('loadeddata', handleVideoLoaded);

                    video.pause();
                    video.classList.remove('show');
                    if (img) {
                        img.classList.remove('hide');
                    }
                }
            });
        }
    });
}