const breakpoint = 1024;
const header = document.querySelector('header');

if(header) {
    const wrapperContent = header.querySelector('.wrapper').innerHTML;
    let headerHeight = header.offsetHeight;
    let lastScrollTop = 0;

    // Menu on scroll
    $(window).scroll(function (e) {
        let scrollTop = $(this).scrollTop();

        // Check if menu is open
        const isMenuOpen = header.classList.contains('show-menu');

        // If the menu is open, keep the header visible and sticky
        if (isMenuOpen) {
            header.classList.add('sticky', 'show');
            if (scrollTop > 0) {
                header.classList.add('animate');
            }
        }
        // Only apply scroll hiding logic if the menu is NOT open
        else {
            if ($(window).width() >= breakpoint && scrollTop <= 2) {
                header.classList.remove('sticky', 'show', 'animate');
            }
            else if (scrollTop === 0) {
                header.classList.remove('sticky', 'show', 'animate');
            }
            else if (scrollTop > headerHeight) {
                if (scrollTop < lastScrollTop) { // Scrolling up
                    header.classList.add('sticky', 'animate', 'show')
                } else {
                    header.classList.add('sticky');
                    header.classList.remove('show');
                }
            }
        }
        lastScrollTop = scrollTop;
    });

    setMenuNav();

    window.addEventListener('resize', function() {
        header.querySelector('.wrapper').innerHTML = wrapperContent;
        setMenuNav();
    });

    function openMenu(menu) {
        const menuElement = document.querySelector("." + menu + '-menu');
        if (menuElement) {
            menuElement.classList.add('show');
        }

        const menuContent = document.querySelector("header .menu-content");
        if (menuContent) {
            menuContent.classList.add("show");
        }

        header.classList.add('show-menu');

        // Hides dropdown when menu is hidden
        document.querySelectorAll(".menu-item-has-children >a").forEach(function (ele) {
            ele.classList.remove("show-ul");
        });
    }

    function closeMenu() {
        const activeButton = document.querySelector('header button.active');
        if (activeButton) {
            activeButton.classList.remove('active');
        }

        const menuContent = document.querySelector("header .menu-content");
        if (menuContent) {
            menuContent.classList.remove("show");
        }

        header.classList.remove('show-menu');

        setTimeout(function() {
            const showingUl = document.querySelector("ul.show");
            if (showingUl) {
                showingUl.classList.remove('show');
            }
        }, 500);
    }

    function setMenuNav() {
        const menuButton = document.querySelector(".menu-button");
        if (menuButton) {
            menuButton.onclick = function toggleMenu() {
                if(this.classList.contains('active')) {
                    closeMenu();
                }
                else {
                    const investorsMenu = document.querySelector(".investors-menu");
                    if(investorsMenu && investorsMenu.classList.contains('show')) {
                        investorsMenu.classList.remove('show');
                        const activeButton = document.querySelector('header button.active');
                        if (activeButton) {
                            activeButton.classList.remove('active');
                        }
                    }
                    this.classList.add('active');
                    openMenu('main');
                }
            };
        }

        const investorsButton = document.querySelector(".investors-button");
        if (investorsButton) {
            investorsButton.onclick = function toggleMenu() {
                if(this.classList.contains('active')) {
                    closeMenu();
                }
                else {
                    const mainMenu = document.querySelector(".main-menu");
                    if(mainMenu && mainMenu.classList.contains('show')) {
                        mainMenu.classList.remove('show');
                        const activeButton = document.querySelector('header button.active');
                        if (activeButton) {
                            activeButton.classList.remove('active');
                        }
                    }
                    this.classList.add('active');
                    openMenu('investors');
                }
            };
        }

        //dropdown
        /**
         * check if parent has child with classname
         * on click add or remove class
         */
        document.querySelectorAll(".menu-item-has-children >a").forEach(function (ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    window.location = e.target.href;
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
        document.querySelectorAll(".menu-item-has-children >svg").forEach(function(ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    ele.parentNode.classList.remove("show-ul");
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
    }
}